import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, node, string } from 'prop-types';
import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { OWNER, OWNER_USER_TYPE, RENTER, RENTER_USER_TYPE, propTypes } from '../../../util/types';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';
import * as validators from '../../../util/validators';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
} from '../../../util/validators';
import {
  Button,
  CustomExtendedDataField,
  FieldLocationAutocompleteInput,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../../components';

import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldDisplayName from '../UserFieldDisplayName';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';

import css from './SignupForm.module.css';
import { uploadToR2 } from '../../../util/api';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;
const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const identity = v => v;
const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    initialValues={{ userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes) }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        onImageUpload,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        preselectedUserType,
        userTypes,
        userFields,
        values,
        form,
        uploadImageError,
        uploadInProgress,
        marketplaceName,
        autoFocus,
        listingImageConfig,
        step,
        setStep,
      } = formRenderProps;

      const filteredUserFields = userFields?.filter(field => field.key !== 'DriversLicenseNumber');
      const { userType } = values || {};

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );
      // Bio
      const bioLabel = intl.formatMessage({
        id: 'ProfileSettingsForm.bioLabel',
      });
      const bioPlaceholder = intl.formatMessage({
        id: 'ProfileSettingsForm.bioPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });
      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });
      // Custom user fields. Since user types are not supported here,
      // only fields with no user type id limitation are selected.
      const userFieldProps = getPropsForCustomUserFieldInputs(filteredUserFields, intl, userType);
      const firstFourFields = userFieldProps.slice(0, 4); // Get the first 4 fields
      const remainingFourFields = userFieldProps.slice(4); // Get the remaining 4 fields

      const noUserTypes = !userType && !(userTypes?.length > 0);
      const userTypeConfig = userTypes.find(config => config.userType === userType);
      const showDefaultUserFields = userType || noUserTypes;
      const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const [imagePreviewUrl, setImagePreviewUrl] = useState(null); // Add state for image preview
      const [licenseImages, setLicenseImages] = useState({
        front: null,
        back: null,
      });

      const handleNext = () => {
        setStep(p => p + 1);
      };
      const handleBack = () => {
        setStep(p => p - 1);
      };

      const handleImageChange = (side, file, form, fieldName) => {
        if (file) {
          try {
            setLicenseImages(prev => ({
              ...prev,
              [side]: URL.createObjectURL(file),
            }));
            const currentValues = form.getState().values;
            const updatedImages = [
              ...(currentValues[fieldName] || []).filter(img => img.side !== side),
              {
                side,
                file, // Include file data if necessary
              },
            ];
            form.change(fieldName, updatedImages);
            form.blur(fieldName);
          } catch (error) {
            console.log('error', error);
          }
        }
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {(() => {
            switch (step) {
              case 1:
                return (
                  <>
                    <div className={css.joinusHeading}>
                      Let's Get <a>Started</a>
                    </div>

                    <FieldSelectUserType
                      name="userType"
                      userTypes={userTypes}
                      hasExistingUserType={!!preselectedUserType}
                      intl={intl}
                    />

                    <div className={css.marginBottom}>
                      <Field
                        accept={ACCEPT_IMAGES}
                        id="profileImage"
                        name="profileImage"
                        type="file"
                        form={null}
                        uploadImageError={uploadImageError}
                      >
                        {fieldProps => {
                          const { accept, id, input, label, disabled } = fieldProps;
                          const { name, type } = input;

                          const onChange = e => {
                            const file = e.target.files[0];
                            if (file) {
                              const tempId = `${file.name}_${Date.now()}`;
                              onImageUpload({ id: tempId, file, listingImageConfig });
                              setImagePreviewUrl(URL.createObjectURL(file)); // Set the image preview URL
                            }
                            form.change('profileImage', file);
                            form.blur('profileImage');
                          };

                          return (
                            <div className={css.uploadAvatarWrapper}>
                              <label htmlFor={id} className={css.label}>
                                {imagePreviewUrl == null ? (
                                  <span className={css.avatarPlaceholder} htmlFor="planimetry">
                                    <span>+ Profile Image</span>
                                  </span>
                                ) : (
                                  <div className={css.avatarContainer}>
                                    <img
                                      src={imagePreviewUrl}
                                      alt="Preview"
                                      className={css.avatarImage}
                                    />
                                  </div>
                                )}
                              </label>
                              <input
                                accept={accept}
                                id={id}
                                name={name}
                                className={css.uploadAvatarInput}
                                disabled={disabled}
                                onChange={onChange}
                                type={type}
                              />
                              {uploadImageError && (
                                <div className={css.error}>
                                  <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                                </div>
                              )}
                            </div>
                          );
                        }}
                      </Field>
                    </div>

                    {showDefaultUserFields ? (
                      <div className={css.defaultUserFields}>
                        <FieldTextInput
                          type="email"
                          id={formId ? `${formId}.email` : 'email'}
                          name="email"
                          autoComplete="email"
                          label={intl.formatMessage({
                            id: 'SignupForm.emailLabel',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'SignupForm.emailPlaceholder',
                          })}
                          validate={validators.composeValidators(emailRequired, emailValid)}
                        />
                        <div className={css.name}>
                          <FieldTextInput
                            className={css.firstNameRoot}
                            type="text"
                            id={formId ? `${formId}.fname` : 'fname'}
                            name="fname"
                            autoComplete="given-name"
                            label={intl.formatMessage({
                              id: 'SignupForm.firstNameLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'SignupForm.firstNamePlaceholder',
                            })}
                            validate={validators.required(
                              intl.formatMessage({
                                id: 'SignupForm.firstNameRequired',
                              })
                            )}
                          />
                          <FieldTextInput
                            className={css.lastNameRoot}
                            type="text"
                            id={formId ? `${formId}.lname` : 'lname'}
                            name="lname"
                            autoComplete="family-name"
                            label={intl.formatMessage({
                              id: 'SignupForm.lastNameLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'SignupForm.lastNamePlaceholder',
                            })}
                            validate={validators.required(
                              intl.formatMessage({
                                id: 'SignupForm.lastNameRequired',
                              })
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                    <Button
                      className={css.nextButton}
                      type="button"
                      disabled={!values.email || !values.userType}
                      onClick={handleNext}
                    >
                      <FormattedMessage id="SignupPage.nextButton" />
                    </Button>
                    <div className={css.socialButtonsOr}>
                      <span className={css.socialButtonsOrText}>
                        <FormattedMessage id="AuthenticationPage.or" />
                      </span>
                    </div>
                    <div className={css.signUpLink}>
                      Already have an account? <a href="/login">Log in</a>
                    </div>
                  </>
                );
              case 2:
                return (
                  <>
                    <FieldTextInput
                      className={css.password}
                      type="password"
                      id={formId ? `${formId}.password` : 'password'}
                      name="password"
                      autoComplete="new-password"
                      label={intl.formatMessage({
                        id: 'SignupForm.passwordLabel',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SignupForm.passwordPlaceholder',
                      })}
                      validate={passwordValidators}
                    />
                    <UserFieldPhoneNumber
                      formName="SignupForm"
                      className={css.row}
                      userTypeConfig={userTypeConfig}
                      intl={intl}
                    />
                    <UserFieldDisplayName
                      formName="SignupForm"
                      className={css.row}
                      userTypeConfig={userTypeConfig}
                      intl={intl}
                    />
                    {userType ? (
                      <div>
                        <FieldLocationAutocompleteInput
                          rootClassName={css.locationAddress}
                          inputClassName={css.locationAutocompleteInput}
                          iconClassName={css.locationAutocompleteInputIcon}
                          predictionsClassName={css.predictionsRoot}
                          validClassName={css.validLocation}
                          autoFocus={autoFocus}
                          name="location"
                          label={intl.formatMessage({
                            id: 'EditListingLocationForm.address',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'EditListingLocationForm.addressPlaceholder',
                          })}
                          useDefaultPredictions={false}
                          format={identity}
                          valueFromForm={values.location}
                          validate={composeValidators(
                            autocompletePlaceSelected(addressNotRecognizedMessage)
                          )}
                        />
                        <FieldTextInput
                          className={css.building}
                          type="text"
                          name="building"
                          id={`${formId}building`}
                          label={intl.formatMessage(
                            { id: 'EditListingLocationForm.building' },
                            { optionalText }
                          )}
                          placeholder={intl.formatMessage({
                            id: 'EditListingLocationForm.buildingPlaceholder',
                          })}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <div className={css.actionButtons}>
                      <Button type="button" onClick={handleBack}>
                        <FormattedMessage id="SignupPage.backButton" />
                      </Button>
                      <Button
                        type="button"
                        disabled={!values.password}
                        onClick={handleNext}
                        className={css.nextButton}
                      >
                        <FormattedMessage id="SignupPage.nextButton" />
                      </Button>
                    </div>
                  </>
                );
              case 3:
                return (
                  <>
                    {userType ? (
                      <div className={classNames(css.sectionContainer, css.marginBottom)}>
                        <FieldTextInput
                          type="textarea"
                          id="bio"
                          name="bio"
                          label={bioLabel}
                          placeholder={bioPlaceholder}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {showCustomUserFields ? (
                      <div className={css.customFields}>
                        {firstFourFields?.map(fieldProps => (
                          <CustomExtendedDataField
                            form={form}
                            values={values}
                            {...fieldProps}
                            formId={formId}
                          />
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className={css.actionButtons}>
                      <Button type="button" onClick={handleBack}>
                        <FormattedMessage id="SignupPage.backButton" />
                      </Button>
                      {userType === RENTER_USER_TYPE ? (
                        <div className={css.bottomWrapper}>
                          {termsAndConditions}
                          <PrimaryButton
                            type="submit"
                            inProgress={submitInProgress}
                            disabled={submitDisabled}
                          >
                            <FormattedMessage id="SignupForm.signUp" />
                          </PrimaryButton>
                        </div>
                      ) : (
                        <Button
                          type="button"
                          disabled={[RENTER, OWNER].includes(!userType)}
                          onClick={handleNext}
                          className={css.nextButton}
                        >
                          <FormattedMessage id="SignupPage.nextButton" />
                        </Button>
                      )}
                    </div>
                  </>
                );

              case 4:
                return (
                  <>
                    {showCustomUserFields ? (
                      <>
                        <div className={css.customFields}>
                          {remainingFourFields?.map(fieldProps => (
                            <CustomExtendedDataField {...fieldProps} formId={formId} />
                          ))}
                        </div>
                        {/* <div className={css.marginBottom}>
                          <Field
                            accept={ACCEPT_IMAGES}
                            id="pub_licenseImages"
                            name="licenseImages"
                            type="file"
                            multiple
                            form={null}
                            uploadImageError={uploadImageError}
                          >
                            {({ input, meta }) => {
                              const { accept, id, disabled } = input;
                              const { name, type } = input;

                              // Helper function to handle front/back image changes
                              const handleImageChangeInternal = side => e => {
                                const file = e.target.files[0];
                                handleImageChange(side, file, form, name);
                              };

                              return (
                                <>
                                  <div className={css.uploadAvatarWrapper}>
                                    <label htmlFor={`${id}-front`} className={css.label}>
                                      {licenseImages.front == null ? (
                                        <span className={css.avatarPlaceholder}>
                                          <span>+ License Front Image</span>
                                        </span>
                                      ) : (
                                        <div className={css.avatarContainer}>
                                          <img
                                            src={licenseImages.front}
                                            alt="License Front Preview"
                                            className={css.avatarImage}
                                          />
                                        </div>
                                      )}
                                    </label>
                                    <input
                                      accept={accept}
                                      id={`${id}-front`}
                                      name={name}
                                      className={css.uploadAvatarInput}
                                      disabled={disabled}
                                      onChange={handleImageChangeInternal('front')}
                                      type={type}
                                    />
                                  </div>

                                  <div className={css.uploadAvatarWrapper}>
                                    <label htmlFor={`${id}-back`} className={css.label}>
                                      {licenseImages.back == null ? (
                                        <span className={css.avatarPlaceholder}>
                                          + License Back Image
                                        </span>
                                      ) : (
                                        <div className={css.avatarContainer}>
                                          <img
                                            src={licenseImages.back}
                                            alt="License Back Preview"
                                            className={css.avatarImage}
                                          />
                                        </div>
                                      )}
                                    </label>
                                    <input
                                      accept={accept}
                                      id={`${id}-back`}
                                      name={name}
                                      className={css.uploadAvatarInput}
                                      disabled={disabled}
                                      onChange={handleImageChangeInternal('back')}
                                      type={type}
                                    />
                                  </div>
                                </>
                              );
                            }}
                          </Field>
                        </div> */}
                      </>
                    ) : (
                      ''
                    )}
                    <div className={css.actionButtons}>
                      <Button type="button" onClick={handleBack}>
                        <FormattedMessage id="SignupPage.backButton" />
                      </Button>
                    </div>
                    <div className={css.bottomWrapper}>
                      {termsAndConditions}
                      <PrimaryButton
                        type="submit"
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                      >
                        <FormattedMessage id="SignupForm.signUp" />
                      </PrimaryButton>
                    </div>
                  </>
                );
            }
          })()}
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
